import React from "react"
import BlogCard from "./BlogCard"
import EventCard from "./EventCard"

export default function CardRow({ contentRow, columnLimit, type }) {
  const emptyColumns = []
  var emptyColumnNo = columnLimit - contentRow.length
  while (emptyColumnNo > 0) {
    emptyColumns.push(<div className="column" key={emptyColumnNo}></div>)
    emptyColumnNo--
  }
  return (
    <React.Fragment>
      {contentRow.map(content => {
        return (
          <div className="column" key={content.id}>
            {type === "blog" ? <BlogCard blogPost={content} /> : null}
            {type === "event" ? <EventCard event={content} /> : null}
          </div>
        )
      })}
      {emptyColumns}
    </React.Fragment>
  )
}
