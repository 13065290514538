import React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

export default function BlogCard({ blogPost }) {
  const { coverImage, title, tags, excerpt, updatedAt, author, slug } = blogPost
  const coverImageUrl = coverImage.url
  const { language } = useI18next()
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  const updatedDate = new Date(updatedAt)
  var pathPrefix = "/blog/"

  return (
    <div
      className="card"
      style={{
        boxShadow:
          "0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 15%)",
        height: "100%",
      }}
    >
      <Link to={pathPrefix + slug}>
        <div className="card-image">
          <figure className="image is-4by3">
            <img src={coverImageUrl} alt={title} />
          </figure>
        </div>
      </Link>
      <div className="card-content block">
        <div className="media-content">
          <div className="block" style={{ minHeight: "110px" }}>
            <Link to={pathPrefix + slug}>
              <p className="title is-4">{title}</p>
            </Link>
          </div>
          {tags ? (
            <div className="block">
              {tags.map(tag => (
                <span className="tag is-primary ml-1" key={tag}>
                  {tag}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className="content mt-4" style={{ minHeight: "120px" }}>
          {excerpt}
        </div>
        <hr />
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <img src={author.picture.url} alt={author.name} />
            </figure>
          </div>
          <div className="media-content">
            <p className="has-text-weight-bold">{author.name}</p>
            <time dateTime={updatedDate.toISOString()} className="is-italic">
              {updatedDate.toLocaleDateString(language, options)}
            </time>
          </div>
        </div>
      </div>
    </div>
  )
}
